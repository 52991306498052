.dashboard {
    display:flex;
}
.layout1{
    width: 50%;
    padding:20px;
}
.layout2{
    width:50%;
    padding:20px;
    padding-bottom: 20px;
    display:flex-column;
}
.mapPanel {
    width: 100%;
    height: 550vh;
}

.faultData {
    width: 100%;
}

.faultTable {
    width: 100%;
}
  