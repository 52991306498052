@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("/src/assets/Poppins-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

:root,
.ant-card,
[class^="ant-row"],
[class^="ant-col"],
.ant-radio-wrapper,
.ant-modal {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

[class*=" ant-btn"] {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: "Poppins", sans-serif;
}

.logo {
  margin: 0 0 0 1rem;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.ant-layout-header {
  padding-inline: 10px;
}

.margin-center {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.h1-header {
  font-size: 26px;
  font-weight: 600;
}

.sub-header {
  font-size: 14px;
  color: #475467;
}

.mt-space {
  margin: 1rem 0;
}

.header {
  font-weight: 600;
  font-size: 16px;
}

.grey-bg {
  background-color: rgb(240, 242, 245);
  min-height: 668px;
  display: flex;
}

.mt4 {
  margin-top: 4rem;
}

.ant-dropdown-menu {
  min-width: 130px;
}

.ant-space {
  cursor: pointer;
}

.Toastify__close-button > button,
[type="button"] {
  background-color: unset !important;
  width: unset;
  font-size: unset;
  font-weight: unset;
  font-weight: unset;
  color: unset;
}

.mt1 {
  margin-top: 1rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.ant-menu-dark {
  color: rgba(255, 255, 255, 0.85);
}

.start {
  background-color: #039855 !important;
  height: 42px !important;
}

.start:disabled {
  background-color: #90ae8a !important;
}

.ant-btn-primary.ant-btn-dangerous:disabled {
  background-color: #ff9a9b !important;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: #ff4d4f !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-btn.ant-btn-icon-only {
  height: auto !important;
}

.right {
  float: right;
}

.ant-btn-submit {
  background-color: #f16521 !important;
}

a {
  color: #f16521;
}

.dp {
  background-color: #d9d9d9;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #f16521;
}

.bold {
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-12upa3x) > button,
.ant-btn .ant-btn-default {
  color: rgba(0, 0, 0, 0.88) !important;
}

.card-sub-text {
  color: #667085;
}

.card-bold-text {
  color: #1d2939;
  font-weight: 600;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1d2939;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #1d2939;
}

.ant-tabs .ant-tabs-tab-btn {
  color: #667085;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #1d2939;
}

.mode-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.ant-modal-footer > .ant-btn-submit {
  background-color: #fff;
  color: #000;
}

.ant-modal-close {
  color: rgba(0, 0, 0, 0.45) !important;
  height: 0 !important;
}

.ant-modal-close-x {
  background: #000;
  border-radius: 1rem;
  color: #fff;
  font-size: 14px;
  padding: 0.2rem;
  font-weight: 600;
}

.comment-btn {
  border-radius: 40px !important;
  background: #1d2939 !important;
  max-height: 38px !important;
  width: 100px !important;
}

.ant-form-item .ant-form-item-explain-error {
  margin-bottom: 1rem;
}

.ant-spin .ant-spin-dot-item {
  background-color: #f16521;
}

.play {
  max-height: 28px !important;
  width: 34px !important;
  padding: 0;
}

.no-padding-w-box {
  background-color: #fff;
  border-radius: 10px;
}

.ant-pagination-item-link {
  color: #aeaeae !important;
  height: 35px !important;
}

.blur {
  filter: blur(3px);
}
