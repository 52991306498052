.timer {
  width: 100%;
  padding: 0.5rem;
  font-size: 15px;
  border: 1px solid #d9d9d9;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
  color: #98a2b3;
}

.h-mb {
  margin: 0 0 10px 0;
}
