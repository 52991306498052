.speed {
  padding: 5px 6px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #667085;
}

.cursor-point {
  cursor: pointer;
}

textarea {
  border-radius: 0 !important;
}
