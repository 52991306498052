.ant-menu-light .ant-menu-item-selected {
  background-color: #1d2939;
  color: #fff;
}

.ant-menu-vertical .ant-menu-item {
  margin-inline: 3px;
}

.ant-menu .ant-menu-title-content {
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
}

[class*=" ant-dropdown"] {
  margin-right: 0.65rem;
}

.flex {
  display: flex;
}

.mr075 {
  margin-right: 0.75rem;
}
