@media screen and (max-width: 480px) {
  .brand {
    display: none;
  }
  .ant-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.banner {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/brand.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
